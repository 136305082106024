.lint {
  color: blue;
}

$light-outline-color: #fff;
$dark-outline-color: #000;

@mixin lightBackgroundDarkOutline {
  *:focus-visible,
  .Mui-focusVisible,
  .MuiInputBase-root.Mui-focused:not(.MuiOutlinedInput-root, .MuiInput-underline),
  .MuiButton-contained:focus, .MuiButton-outlined:focus, .MuiCheckbox:focus {
    outline: dashed;
    outline-width: 1px;
    outline-offset: 5px;
    outline-color: $dark-outline-color;
  }
}

@mixin darkBackgroundLightOutline {
  *:focus-visible,
  .Mui-focusVisible,
  .MuiInputBase-root.Mui-focused:not(.MuiOutlinedInput-root, .MuiInput-underline),
  .MuiButton-contained:focus, .MuiButton-outlined:focus, .MuiCheckbox:focus {
    outline: dashed;
    outline-width: 1px;
    outline-offset: 5px;
    outline-color: $light-outline-color;
  }
}

@include lightBackgroundDarkOutline;

.focusVisible--lightBackgroundDarkOutline {
  @include lightBackgroundDarkOutline;
}

.focusVisible--darkBackgroundLightOutline {
  @include darkBackgroundLightOutline;
}
