.Header {
    &-left::after {
    content: '';
    width: 30px;
    background: linear-gradient(to left, white, transparent);
    position: absolute;
    top: 0;
    bottom: 0;
    right: 0;
  }

  &-right {
    display: flex;
    flex: 1 0 max-content;
    justify-content: flex-end;
    align-items: flex-start;
  }

  &-image {
    display: block;
    max-width: 100%;
    
    &Right {
      width: 200px;
    }
  }
}
